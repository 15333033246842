import { useState } from 'react';
import './App.css';

function App() {
  const [selectedMap, setSelectedMap] = useState('https://map1.lunecraft.net');

  const handleMapChange = (event) => {
    setSelectedMap(event.target.value);
  };

  return (
    <div className="App">
      <select 
        className="map-selector" 
        onChange={handleMapChange} 
        value={selectedMap}
      >
        <option value="https://map1.lunecraft.net">Towny 1</option>
        <option value="https://map2.lunecraft.net">Towny 2</option>
      </select>
      
      <iframe
        src={selectedMap}
        className="map-iframe"
        title="Lunecraft Map"
        frameBorder="0"
      ></iframe>
    </div>
  );
}

export default App;
